import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
export default class default_1 extends Controller {
    static values = {
        mobileMenuUrl: String,
        destinationsMenuUrl: String,
        aboutMenuUrl: String,
    };
    static targets = [
        "destinationsArrow",
        "destinationsMenu",
        "aboutArrow",
        "aboutMenu",
        "mobileMenu"
    ];
    isDestinationsOpen = false;
    isAboutOpen = false;
    isMobileMenuOpen = false;
    isMobileMenuLoaded = false;
    isDestinationsMenuLoaded = false;
    isAboutMenuLoaded = false;
    connect() {
        // @ts-ignore
        useClickOutside(this);
    }
    disconnect() {
    }
    clickOutside(event) {
        if (this.isDestinationsOpen) {
            event.preventDefault();
            this.hideDestinations();
        }
        if (this.isAboutOpen) {
            event.preventDefault();
            this.hideAbout();
        }
    }
    // noinspection JSUnusedGlobalSymbols
    hideAll() {
        if (this.isDestinationsOpen) {
            this.hideDestinations();
        }
        if (this.isAboutOpen) {
            this.hideAbout();
        }
        if (this.isMobileMenuOpen) {
            this.hideMobileMenu();
        }
    }
    disableBodyScroll() {
        document.body.style.overflow = 'hidden';
    }
    enableBodyScroll() {
        document.body.style.overflow = '';
    }
    toggleDestinations() {
        if (this.isDestinationsOpen) {
            this.hideDestinations();
        }
        else if (this.isAboutOpen && this.hasAboutMenuTarget) {
            this.hideAbout(0);
            this.showDestinations(0);
        }
        else {
            this.showDestinations();
        }
    }
    toggleAbout() {
        if (this.isAboutOpen) {
            this.hideAbout();
        }
        else if (this.isDestinationsOpen && this.hasDestinationsMenuTarget) {
            this.hideDestinations(0);
            this.showAbout(0);
        }
        else {
            this.showAbout(-5);
        }
        this.setDestinationsArrow();
    }
    setDestinationsArrow() {
        if (this.hasDestinationsArrowTarget) {
            if (this.isDestinationsOpen) {
                this.destinationsArrowTarget.classList.add("scale-y-[-1]");
            }
            else {
                this.destinationsArrowTarget.classList.remove("scale-y-[-1]");
            }
        }
    }
    async showDestinations(y = -5) {
        if (this.hasDestinationsMenuTarget) {
            this.isDestinationsOpen = true;
            await this.show(this.destinationsMenuTarget, this.destinationsArrowTarget, y);
            if (!this.isDestinationsMenuLoaded) {
                this.isDestinationsMenuLoaded = true;
                const response = await fetch(this.destinationsMenuUrlValue);
                this.destinationsMenuTarget.innerHTML = await (response ? response.text() : Promise.resolve(''));
            }
            if (window._paq) {
                window._paq.push(['trackContentInteraction', 'click', 'Navigation', 'Reiseziele', window.location.href]);
            }
        }
    }
    hideDestinations(y = -5) {
        if (this.hasDestinationsMenuTarget) {
            this.isDestinationsOpen = false;
            this.hide(this.destinationsMenuTarget, this.destinationsArrowTarget, y);
        }
    }
    async showAbout(y = -5) {
        if (this.hasAboutMenuTarget) {
            this.isAboutOpen = true;
            await this.show(this.aboutMenuTarget, this.aboutArrowTarget, y);
            if (!this.isAboutMenuLoaded) {
                this.isAboutMenuLoaded = true;
                const response = await fetch(this.aboutMenuUrlValue);
                this.aboutMenuTarget.innerHTML = await (response ? response.text() : Promise.resolve(''));
            }
            if (window._paq) {
                window._paq.push(['trackContentInteraction', 'click', 'Navigation', 'Über Umfulana', window.location.href]);
            }
        }
    }
    hideAbout(y = -5) {
        if (this.hasAboutMenuTarget) {
            this.isAboutOpen = false;
            this.hide(this.aboutMenuTarget, this.aboutArrowTarget, y);
        }
    }
    // noinspection JSUnusedGlobalSymbols
    async showMobileMenu() {
        if (this.hasMobileMenuTarget && !this.isMobileMenuOpen) {
            this.isMobileMenuOpen = true;
            this.disableBodyScroll();
            const GSAP = await import("gsap");
            GSAP.default.timeline()
                .set(this.mobileMenuTarget, {
                visibility: "visible",
                display: "inherit",
                opacity: 0
            })
                .to(this.mobileMenuTarget, {
                duration: 0.4,
                ease: GSAP.Power2.easeOut,
                opacity: 1
            });
            const tourMapButton = document.querySelector('[data-tour-target="mobileMapButton"]');
            if (tourMapButton) {
                tourMapButton.classList.add('hidden');
            }
            if (!this.isMobileMenuLoaded) {
                this.isMobileMenuLoaded = true;
                const response = await fetch(this.mobileMenuUrlValue);
                this.mobileMenuTarget.innerHTML = await (response ? response.text() : Promise.resolve(''));
            }
            if (window._paq) {
                window._paq.push(['trackContentInteraction', 'click', 'Navigation', 'Menü (Mobil)', window.location.href]);
            }
        }
    }
    async hideMobileMenu() {
        if (this.hasMobileMenuTarget && this.isMobileMenuOpen) {
            this.isMobileMenuOpen = false;
            this.enableBodyScroll();
            const GSAP = await import("gsap");
            GSAP.default.timeline()
                .to(this.mobileMenuTarget, {
                duration: 0.15,
                ease: GSAP.Power2.easeIn,
                opacity: 0
            })
                .set(this.mobileMenuTarget, {
                visibility: "hidden",
                display: "none"
            });
            const tourMapButton = document.querySelector('[data-tour-target="mobileMapButton"]');
            if (tourMapButton) {
                tourMapButton.classList.remove('hidden');
            }
        }
    }
    // Entering: "transition ease-out duration-200"
    // From: "opacity-0 -translate-y-1"
    // To: "opacity-100 translate-y-0"
    async show(target, arrow, y = -5) {
        const GSAP = await import("gsap");
        GSAP.default.timeline()
            .set(arrow, {
            scaleY: -1
        })
            .set(target, {
            visibility: "visible",
            opacity: 0,
            y
        })
            .to(target, {
            duration: 0.2,
            ease: GSAP.Power2.easeOut,
            opacity: 1,
            y: 0
        });
    }
    // Leaving: "transition ease-in duration-150"
    // From: "opacity-100 translate-y-0"
    // To: "opacity-0 -translate-y-1"
    async hide(target, arrow, y = -5) {
        const GSAP = await import("gsap");
        GSAP.default.timeline()
            .set(arrow, {
            scaleY: 1
        })
            .to(target, {
            duration: 0.15,
            ease: GSAP.Power2.easeIn,
            opacity: 0,
            y
        })
            .set(target, {
            visibility: "hidden"
        });
    }
}
