import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
    // @ts-ignore
    element;
    send(event) {
        const params = event.params || {};
        const type = event.type || "click";
        if (window._paq && params.name) {
            const target = params.target || window.location.href;
            let piece = params.piece;
            if (!piece && event.currentTarget.nodeName && type === "change") {
                piece = event.currentTarget.value;
            }
            window._paq.push(['trackContentInteraction', type, params.name, piece, target]);
            window._paq.push(['trackEvent', params.name, piece, params.target || '']);
        }
    }
    conversion(event) {
        const params = event.params || {};
        if (params.name) {
            if (window.gtag) {
                window.gtag("event", params.name, {
                    currency: "EUR",
                    value: 1
                });
            }
            if (window._paq) {
                switch (params.name) {
                    case "calenso":
                        window._paq.push(["trackGoal", 4]);
                        break;
                    case "telefon":
                        window._paq.push(["trackGoal", 5]);
                        break;
                    case "email":
                        window._paq.push(["trackGoal", 6]);
                        break;
                }
            }
            if (window.fbq) {
                window.fbq("trackCustom", params.name);
            }
        }
    }
}
