import {startStimulusApp} from '@symfony/stimulus-bridge';
// @ts-ignore
import Autocomplete from "stimulus-autocomplete";
// @ts-ignore
import {Slideover} from "tailwindcss-stimulus-components";
// @ts-ignore
import TextareaAutogrow from "stimulus-textarea-autogrow";

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(require.context(
	'@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
	true,
	/\.[jt]sx?$/
));

// register any custom, 3rd party controllers here
app.register("autocomplete", Autocomplete);
app.register("textarea-autogrow", TextareaAutogrow);
app.register("slideover", Slideover);

app.debug = false; //process.env.NODE_ENV === 'development';
