import { Controller } from "@hotwired/stimulus";
import { useResize } from "stimulus-use";
export default class extends Controller {
    connect() {
        useResize(this);
        window.addEventListener('resize', this.setDocumentHeight);
        this.setDocumentHeight();
    }
    setDocumentHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    }
    // noinspection JSUnusedGlobalSymbols
    resize() {
        this.setDocumentHeight();
    }
}
